const ua = {
  btn: {
    signin: 'Увійти',
    signup: 'Зареєструватися',
    next: 'Далі',
    send: 'Відправити',
    save: 'Зберегти',
    support: 'Служба підтримки',
    continue: 'Продовжити',
    cancel: 'Скасувати',
    resend: 'Повторно відправити',
    reset: 'Скинути',
    apply: 'Застосувати',
    retry: 'Спробувати ще раз',
    browse: 'Виберіть файл',
    change: 'Змінити',
    add: 'Додати',
    forCoffeeShops: 'Для Кав\'ярень',
    forUsers: 'Для Користувачів',
    deleteProfile: 'Видалити Профіль',
    confirm: 'Підтвердити',
    newRequest: 'Новий запит',
    resetAll: 'Скинути все',
    signInAdm: 'Увійти',
    consultation: 'Замовити консультацію',
  },
  input: {
    firstName: 'Ім\'я',
    lastName: 'Прізвище',
    secondName: 'Прізвище',
    phone: 'Телефон',
    phoneNumber: 'Номер телефону',
    email: 'Ел.пошта',
    oldPassword: 'Старий пароль',
    password: 'Пароль',
    passwordConfirmation: 'Підтвердження пароля',
    confirmPassword: 'Підтвердити пароль',
    networkName: 'Ім\'я мережі',
    website: 'Веб-сайт',
    about: 'Про нас',
    joinLoyalty: 'Приєднатись до системи лояльності',
    address: 'Адреса',
    jobRole: 'Посада',
    vendor: 'Кав\'ярня',
    productName: 'Назва продукту',
    description: 'Опис',
    size: 'Розмір',
    price: 'Ціна',
    supplementName: 'Назва добавки',
    category: 'Категорія',
    language: 'Мова',
    recipientName: 'Ім\'я одержувача',
    recipientBank: 'Банк одержувача',
    iban: 'IBAN',
    checkboxIntegrated: 'Інтеграція з CheckBox',
    ipnEdrpou: 'ІПН/ЄДРПОУ',
    edrpou: 'ЄДРПОУ',
    mfo: 'Код Банку (МФО)',
    amount: 'Сума',
    smsCode: 'Код з СМС',
    emailAdm: 'Електронна пошта',
    passwordAdm: 'Пароль',
    code: 'Код підтвердження',
    companyName: 'Назва компанії',
    checkboxLicenseKey: 'Ключ ліцензії каси',
    selectVendor: 'Обрати кав\'ярню',
    checkboxPinCode: 'Пін-код баристи'
  },
  signInAdm: {
    description: 'Увійдіть, використовуючи дані, які Ви вводили під час реєстрації.',
    form: {
      title: 'Увійдіть в аккаунт',
    },
  },
  actions: {
    edit: 'Редагувати',
    delete: 'Видалити',
    deactivate: 'Деактивувати',
    changePassword: 'Змінити пароль',
    invite: 'Запросити',
    inviteAgain: 'Запросити ще раз',
    saving: 'Збереження',
    saved: 'Збережено',
  },
  weekDayShort: {
    monday: 'Пн',
    tuesday: 'Вт',
    wednesday: 'Ср',
    thursday: 'Чт',
    friday: 'Пт',
    saturday: 'Сб',
    sunday: 'Нд',
  },
  weekDayLong: {
    monday: 'Понеділок',
    tuesday: 'Вівторок',
    wednesday: 'Середа',
    thursday: 'Четвер',
    friday: 'П\'ятниця',
    saturday: 'Субота',
    sunday: 'Неділя',
  },
  signin: {
    title: 'З поверненням',
    description: 'Увійдіть, використовуючи дані, які Ви вводили під час реєстрації.',
    form: {
      title: 'Увійдіть у свій обліковий запис',
      noAccount: 'Ще не маєте облікового запису?',
      chooseMethod: 'Увійдіть за допомогою',
    },
  },
  signup: {
    title: 'Вітаємо у Pinka',
    description: 'Розкажіть, будь ласка, трохи про себе та свій бізнес',
    userForm: {
      title: 'Ваша особиста інформація',
      haveAccount: 'У вас вже є обліковий запис?',
      or: 'Або',
      chooseMethod: 'Зареєструйтеся за допомогою',
      phone: 'Телефону',
      email: 'Ел. пошти',
      resendCodeTimer: 'Ви зможете повторно надіслати код через',
      resendCode: 'Повторно надіслати код',
    },
    networkForm: {
      title: 'Інформація про ваш бізнес',
    },
  },
  forgotPassword: {
    title: 'Забули пароль?',
    description: 'Будь ласка, вкажіть свою електронну адресу, щоб ми надіслали запит на зміну пароля',
  },
  checkEmail: {
    title: 'Перевірте свою електронну пошту',
    description: 'Посилання надіслано на',
    notReceive: 'Не отримали листа?',
  },
  createNewPassword: {
    title: 'Створити новий пароль',
    description: 'Ваш новий пароль має відрізнятися від паролю, які Ви використовували',
  },
  passwordReset: {
    title: 'Зміна паролю',
    description1: 'Ваш пароль успішно змінено!',
    description2: 'Натисніть нижче, щоб увійти до свого облікового запису.',
    descriptionBarista: 'Тепер ви можете увійти через мобільний застосунок!',
  },
  pagination: {
    of: 'з',
    rowsPerPage: 'Рядків на сторінці',
  },
  header: {
    editProfile: 'Налаштування',
    logOut: 'Вийти',
    city: 'Місто',
    vendor: 'Кав\'ярня',
    filter: {
      city: 'Всі міста',
      vendor: 'Всі кав\'ярні',
    },
    changePassword: {
      title: 'Змінити пароль',
      description: 'Ваш новий пароль має відрізнятися від паролю, які Ви використовували',
    },
  },
  profile: {
    delete: 'Видалення',
  },
  notifications: {
    title: 'Сповіщення',
    new: 'Нові',
    archive: 'Архів',
    settings: 'налаштування',
    delete: 'видалити',
    markAllAsRead: 'позначити все як прочитане',
    noNew: 'У вас немає нових сповіщень',
    noOpened: 'У вас немає відкритих сповіщень',
    orders: 'Замовлення',
    network: 'Мережа',
    succesfullyCreated: 'Успішно створено',
    succesfullyUpdated: 'Успішно оновлено',
    succesfullyDeleted: 'Успішно видалено',
    loginSuccess: 'Вітаємо в панелі адміністратора',
    error: 'Щось пішло не так',
    notFound: 'Вибачте, не вдалося знайти.',
    sessionExpired: 'Сесія закінчилася',
    subscriberCreated: 'Успіх! Будь ласка, перевірте свою електронну пошту',
    subscriberEmailDuplicate: 'Не вдалося! Поточна електронна адреса вже використовується',
    nameEmpty: 'Назва не може бути пустою',
    textEmpty: 'Текст не може бути порожнім',
    nameOnlyLetters: 'Використовуйте лише літери для імені',
    emailEmpty: 'Поле електронної пошти не може бути пустим',
    emailInvalid: 'Електронна адреса недійсна',
    mailSent: 'Успішно надіслано',
    maxFive: 'Виберіть до 5 зображень',
    updatedMap: 'Будь ласка, оновіть сторінку, щоб переглянути нове положення на карті',
    mailBackendError: 'Не вдається надіслати запит',
    passwordResetSuccess: 'Ваш пароль успішно оновлено',
    passwordResetError: 'Виникла помила:',
    emailConfirmationSuccess: 'Вашу електронну адресу успішно підтверджено!',
    emailConfirmationRequired: 'Будь ласка, підтвердьте свою електронну адресу. Перевірте свою поштову скриньку.',
    emailConfirmationError: 'Виникла помилка:',
    passwordDoesNotMatch: 'Паролі не збігаються!',
    settingsChangedSuccessfully: 'Налаштування успішно змінено!',
    somethingWentWrok: 'Щось пішло не так',
    codeSent: 'Смс-код було надіслано'
  },
  sidebar: {
    myBalance: 'Мій баланс',
    dashboard: 'Інфо панель',
    orders: 'Замовлення',
    transactions: 'Транзакції',
    network: 'Мережа',
    employees: 'Працівники',
    menu: 'Меню',
    withdrawFunds: 'Вивести кошти',
    settings: 'Налаштування',
    help: 'Допомога',
  },
  help: {
    contact: 'Зв\'яжіться з нами',
  },
  dashboard: {
    title: 'Інформаційна панель',
    dateRange: 'Діапазон дат',
    resultsFor: 'Результати для',
    cityFilter: 'всіх міст',
    vendorFilter: 'всіх кав\'ярень',
    cashTurnover: 'Готівковий обіг',
    total: 'Всього',
    totalOrders: 'Загальна кількість замовлень',
    finished: 'Завершено',
    canceled: 'Скасовано',
    in_progress: 'Готується',
    new: 'Оформлено',
    ready: 'Готово',
    failed: 'Не оплачено',
    salesByCategory: 'Продажі за категоріями',
    drinks: 'Напої',
    desserts: 'Десерти',
    food: 'Їжа',
    goods: 'Товари',
    supplements: 'Добавки',
    baristaEfficiency: 'Ефективність роботи барист',
    sortBy: 'Сортувати',
    barista: 'Бариста',
    revenueUAH: 'Виторг, грн',
    revenue: 'Виторг, грн',
    ascending: 'За зростанням',
    descending: 'За спаданням',
    vendorComparison: 'Порівняння кав\'ярень',
    vendor: 'Кав\'ярня',
    calendarYear: 'Календарний рік',
    calendarMonth: 'Календарний місяць',
    customRange: 'Власний діапазон',
    empty: {
      title: 'Немає даних для відображення',
      description: {
        vendor: 'Графік з\'явиться після того, як Ви додасте кав\'ярню до своєї мережі.',
        baristas: 'Діаграма з\'явиться після того, як Ви додасте барист до своєї мережі.',
      },
    },
  },
  validators: {
    notEmpty: 'Це поле не повинно бути порожнім',
    whiteSpace: 'Поле не повинно складатися лише з пробілів',
    invalidField: 'Це поле недійсне',
    numberFormat: 'Формат: 0000.00 або 0000',
    expiredData: 'Термін дії цих даних минув',
    isMatching: 'Введені паролі не збігаються',
    isFilledShort: 'Пусте поле',
    edrpouRegex: 'Лише 8-10 цифр',
    notValidUrl: 'Формат посилання неправильний',
    correctAddress: 'Адреса повинна містити місто, вулицю та номер',
    mfoRegex: 'Всього 6 цифр',
    minimum: 'Мінімум',
    characters: 'знаків',
    phone: {
      withoutPlus: 'Формат: 380999999999',
    },
    timeFormat: 'Будь ласка, введіть години в правильному форматі 00:00 і не використовуйте інші символи',
    timeRange: 'Будь ласка, введіть правильний проміжок часу',
    liqpayActivationCodeRegex: 'Тільки 8 цифр'
  },
  filters: {
    today: 'Сьогодні',
    week: 'Цього тижня',
    month: 'Цього місяця',
    last7: 'Останні 7 днів',
    last30: 'Останні 30 днів',
    custom: 'Власний діапазон',
    notSelected: 'не вибрано',
  },
  orders: {
    title: 'Замовлення',
    resetAll: 'Скинути все',
    filters: 'Фільтри',
    empty: 'Тут буде відображено список усіх замовлень.',
    orderId: 'ID замовлення',
    date: 'Дата',
    vendor: 'Кав\'ярня',
    barista: 'Бариста',
    payment: 'Оплата',
    status: 'Статус',
    finished: 'завершено',
    canceled: 'скасовано',
    new: 'оформлено',
    in_progress: 'готується',
    ready: 'готово',
    failed: 'не оплачено',
    uah: 'ГРН',
    order: 'Замовлення',
    city: '',
    servicesBy: 'Послуги від',
    orderDetails: 'Деталі замовлення',
    total: 'Всього',
    discount: 'Знижка',
    toBePaid: 'Оплатити',
    allBaristas: 'Усі баристи',
    timePeriod: 'Часовий проміжок',
    orderStatus: 'Статус замовлення',
    anyStatus: 'Будь-який статус',
  },
  network: {
    title: 'Мережа',
    addVendor: 'Додати кав\'ярню',
    customerFeedback: 'Відгуки клієнтів',
    editVendor: 'Редагувати кав\'ярню',
    numberOfVendors: 'Кількість кав\'ярень',
    noVendors: 'Ви ще не додали жодної кав\'ярні до своєї мережі',
    details: 'Відомості про мережу',
    edit: 'Редагувати мережу',
    vendors: 'Кав\'ярні',
    deleteVendor: 'Видалити кав\'ярню',
    viewMenu: 'Переглянути меню',
    about: 'Про нас',
    emptyDescription: 'Розкажіть клієнтам трохи про Вашу мережу...',
    rating: 'Рейтинг',
    stars_one: 'зірка',
    stars_few: 'зірки',
    stars_many: 'зірок',
    stars_other: 'зірок',
    noReviews: 'Ця кав\'ярня ще не отримала жодного відгуку',
    basedOn: 'Ґрунтуючись на',
    reviews: 'відгуках',
    deleteMessage: 'Всі пов\'язані з цим дані також будуть видалені з бази даних',
    open: 'відчинено',
    closed: 'зачинено',
    openingHours: 'Робочі години',
    loadingResults: 'Результати завантаження',
    map: 'Мапа',
    photos: 'Фото',
    loadImages: 'Завантажити',
    activationCode: 'Введіть код активації'
  },
  vendor: {
    mainInfo: 'Основні дані',
    checkboxIntegration: 'Checkbox'
  },
  uploader: {
    maxFiles: 'Ви можете завантажити максимум',
    files: 'файлів',
    uploadingFailed: 'Не вдалося завантажити',
    fileIsLargerThan5MB: 'Розмір файлу перевищує 5 Мб',
    upTo5MB: 'до 5 Мб',
    dragAndDropToUploaderOr: 'Перетягніть, щоб завантажити або',
    uploadUpTo7Photos: 'Завантажте до 7 фотографій',
    photo: 'Фото',
  },
  employees: {
    title: 'Працівники',
    rating: 'Рейтинг',
    empty: 'Тут будуть відображатися додані Вами працівники.',
    addEmployee: 'Додати працівника',
    addNewEmployee: 'Додати нового працівника',
    barista: 'Бариста',
    manager: 'Менеджер',
    employee: 'Працівник',
    owner: 'Власник',
    status: 'Статус',
    role: 'Посада',
    generalInfo: 'Загальна інформація',
    vendor: 'Кав\'ярня',
    email: 'Ел.пошта',
    phone: 'Телефон',
    active: 'акт.',
    inactive: 'неакт.',
    contacts: 'Контакти',
    position: 'Посада',
    changePassword: 'Змінити пароль',
    deactivateTitle: 'Деактивувати працівника?',
    deactivateText: 'Всі пов\'язані з цим дані залишаться в базі даних',
    noVendor: 'Немає кав\'ярень',
    noVendorText: 'Будь ласка, створіть кав\'ярню, перш ніж додавати працівників',
    feedbacks_one: '{{count}} відгук',
    feedbacks_other: '{{count}} відгуки',
    feedbacks_many: '{{count}} відгуків',
    loginData: 'Облікові дані'
  },
  menu: {
    title: 'Меню',
    poster: 'Ви використовуєте сервіс Poster для меню',
    back: 'Повернутися',
    inputTitle: 'Введіть назву компанії',
    deletePoster: 'Видалити Poster',
    companyName: 'Назва компанії',
    submit: 'Підтвердити',
    empty: 'Наразі немає доступних типів',
    emptyCategories: 'Тут будуть відображені категорії меню, які Ви додали.',
    noMoreCategories: 'Ви додали всі категорії',
    addCategory: 'Додати категорію',
    removeCategoryText: 'Всі додані товари також будуть видалені з меню мережі',
    areYouSure: 'Позиція буде видалена з меню мережі. Ви впевнені?',
    addProduct: 'Додати товар',
    deleteCategory: 'Видалити категорію',
    addSupplement: 'Додати добавку',
    coffeeType: 'Тип кави',
    coffee: 'Кава',
    cocoa: 'Какао',
    tea: 'Чай',
    smoothies: 'Смузі',
    'refreshing-drinks': 'Освіжаючі напої',
    sweets: 'Cолодощі',
    'sweet-bakery': 'Солодка випічка',
    'ice-cream': 'Морозиво',
    salad: 'Салати',
    bakery: 'Випічка',
    sandwiches: 'Бутерброди',
    'fast-food': 'Фастфуд',
    breakfasts: 'Сніданки',
    'packaged-goods': 'Упакований товар',
    'goods-for-coffee': 'Товари для кави',
    milk: 'Молоко',
    toppings: 'Топінги',
    additionally: 'Додатково',
    arabica: 'Арабіка',
    robusta: 'Робуста',
    mix: 'Мікс',
    uah: 'грн',
    availability: 'Наявність',
  },
  month: {
    January: 'Січень',
    February: 'Лютий',
    March: 'Березень',
    April: 'Квітень',
    May: 'Травень',
    June: 'Червень',
    July: 'Липень',
    August: 'Серпень',
    September: 'Вересень',
    October: 'Жовтень',
    November: 'Листопад',
    December: 'Грудень',
  },
  withdrawFunds: {
    create: {
      title: 'Виведення коштів',
      paymentMethod: 'Рахунок',
      amount: 'Сума',
      uah: 'грн',
      notFound: 'Не знайдено',
      availableFunds: 'Доступні кошти',
      addPaymentMethod: 'Додайте рахунок',
      notPaymentMethod: 'Ви ще не додали рахунок. Будь ласка, перейдіть до налаштувань.',
    },
    show: {
      title: 'Виведення коштів',
      recipientAccount: 'Рахунок отримувача',
      recipientBank: 'Банк отримувача',
      recipientName: 'Ім\'я отримувача',
      bankCode: 'Код банку',
      mfoCode: 'Код МФО',
      amount: 'Сума',
      transactionId: 'ID Транзакції',
    },
    element: {
      bankAccount: 'Код банку',
    },
    filters: {
      timePeriod: 'Період часу',
      paymentStatus: 'Статус платежу',
    },
    hints: {
      availableNew: 'Нова транзакція буде доступна після завершення поточної.',
    },
    tableHeaders: {
      id: 'ID виведення коштів',
      date: 'Дата',
      amount: 'Сума',
      method: 'Спосіб виведення коштів',
      status: 'Статус',
    },
    statusAny: 'Будь-який статус',
    statusSent: 'Запит відправлено',
    statusPending: 'У процесі',
    statusSuccess: 'Завершено',
    statusFailed: 'Помилка',
    title: 'Вивести кошти',
    transaction: 'Транзакція',
    addWithdrawMethod: 'Додати метод виведення коштів',
    withdrawalHistory: 'Історія виведення коштів',
    empty: {
      noWithdrawal: 'Немає зняття коштів для відображення',
      noResult: 'Немає результатів по заданим критеріям фільтрації.',
      tryAgain: 'Налаштуйте або скиньте фільтри та спробуйте ще раз',
    },
  },
  settings: {
    title: 'Налаштування',
    editProfile: {
      title: 'Редагувати профіль',
      ukrainian: 'Українська',
      english: 'English',
      profileInfo: 'Інформація профілю',
      deleteProfile: 'Видалити Ваш Профіль?',
    },
    deleteProfile: {
      title: 'Видалити профіль?',
    },
    withdrawalMethod: {
      title: 'Метод виведення коштів',
      myMethods: 'Мої методи виведення коштів',
      newMethod: 'Новий метод виведення коштів',
      bankAccount: 'Банківський рахунок',
      setAsDefault: 'Встановити метод виведення за замовчуванням',
      recipient: 'Одержувач',
      delete: 'Видалити метод виведення коштів?',
      deleteDescription: 'Всі пов\'язані з цим дані також будуть видалені з бази даних',
      wrongCode: 'Не правильний код',
    },
    notifications: {
      title: 'Сповіщення',
      description: 'Ви можете обрати, які сповіщення хочете отримувати',
      orderFinished: 'Замовлення виконано',
      orderCanceled: 'Замовлення скасовано',
      customerFeedback: 'Відгуки клієнтів',
      withdrawalSuccess: 'Заявка на виведення коштів виконана успішно',
      withdrawalFailed: 'Заявку на виведення коштів виконати не вдалося',
      orderFinishedText: 'Сповіщення про виконані замовлення',
      orderCanceledText: 'Сповіщення про скасовані замовлення.',
      customerFeedbackText: 'Сповіщення про нові відгуки про Ваші кав\'ярні',
      withdrawalSuccessText: 'Сповіщення про успішне виведення коштів.',
      withdrawalFailedText: 'Сповіщення про невдале виведення коштів.',
    },
  },
  deleteInfo: {
    description: 'Всі пов\'язані з цим дані також будуть видалені з бази даних',
    balanceInfo: 'Ви дійсно хочете видалити профіль? На вашому рахунку залишилось ще ',
  },
  liqpayAccount: {
    title: 'Створення Liqpay акаунту',
  },
  receipts: {
    title: 'Надходження',
    order: 'Замовлення',
    uah: 'грн',
    receipts: 'Надходження (грн)',
    date: 'Дата',
    loyalty: 'лояльність',
    commission: 'комісія',
    total: 'Загально'
  },
  hint: {
    checkboxIntegration: 'Система Checkbox використовується для фіскалізації замовлень. ' +
      'Для цього Вам потрібно буде ввести Ключ Ліцензії Каси під час створення кав\'ярні і ' +
      'Пін-код при додаванні баристи. Це потребує окремої реєстрації в системі Checkbox, ' +
      'але врезультаті замовлення будуть фіскалізуватися автоматично. Без інтеграції з Checkbox ' +
      'бариста фіскалізує замовлення через ПРРО кав\'ярні.',
    loginData: 'Дані для входу в застосунок Pinka Barista'
  },
  tutorial: {
    dashboard: {
      title: 'Вітаємо в системі Pinka!',
      textFirstOne: 'Залишилося декілька кроків для початку роботи.',
      textFirstTwo: '1. На сторінці ʼМережа’ додайте інформацію про вашу кав’ярню.',
      textSecond: '2. На сторінці ‘Меню’ додайте або імпортуйте напої.',
      textThird: '3. На сторінці ‘Працівники’ додайте облікові дані барист.'
    },
  },
  networkInfo: {
    description: 'Опис',
    creationDate: 'Дата створення',
    hidden: 'Приховано',
  },
  ownerInfo: {
    owner: 'Власник',
    email: 'Електронна пошта',
    phoneNumber: 'Номер телефону',
    liqpayPhoneNumber: 'Номер телефону LiqPay'
  },
  common: {
    empty: 'Порожньо',
  },
}

export { ua }
