import React from 'react'

import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  Text
} from '../../../UIkit'
import { VerticalDots } from '../../../UIkit/svgs'
import OwnerRow from './OwnerRow'

const tableHeaders = [
  'Owner',
  'Email',
  'Login number',
  'Liqpay number',
  'Created At'
]

const OwnersTable = ({ owners }) => {
  return (
    <Table>
      <TableHead>
        {tableHeaders.map(title => (
          <TableHeader key={title}>
            <Text variant='subheading'>{title}</Text>
          </TableHeader>
        ))}
        <TableHeader>
          <VerticalDots />
        </TableHeader>
      </TableHead>
      <TableBody>
        {owners.map(owner => <OwnerRow key={owner.id} owner={owner} />)}
      </TableBody>
    </Table>
  )
}

export default OwnersTable
