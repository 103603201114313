import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { colors, Text } from '../../UIkit'

const InfoRow = ({ title, text, children }) => {
  const { t } = useTranslation()
  
  const textEmptyCondition = useMemo(() => {
    if (text === 0) return false

    return !text || (text === ' ')
  }, [text])

  if (children) return (
    <div className='staff-info-block__row'>
      <Text variant='body-main1' color={colors.gray[500]}>{title}:</Text>
      {children}
    </div>
  )

  return (
    <div className='staff-info-block__row'>
      <Text variant='body-main1' color={colors.gray[500]}>{title}:</Text>

      {textEmptyCondition ? (
        <Text variant='body-main1' color={colors.primary[500]}>{t('common:empty')}</Text>
      ) : (
        <Text variant='body-main1'>{text}</Text>
      )}
    </div>
  )
}

export default InfoRow
