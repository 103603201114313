import React from 'react'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'

import { Text, Table, TableHead, TableBody, TableHeader, TableRow } from '../../UIkit'
import { VerticalDots } from '../../UIkit/svgs'
import EmployeesElement from './EmployeesElement'

const EmployeesPage = ({ employees, network, updateEmployee }) => {
  const { t } = useTranslation()

  const headers = [
    t('employees:employee'),
    t('employees:status'),
    t('employees:vendor'),
    t('employees:rating'),
    t('employees:email'),
    t('employees:phone'),
  ]

  const desktopHeaders = [
    t('employees:vendor'),
    t('employees:rating'),
    t('employees:email'),
    t('employees:phone'),
  ]

  const mobileHeaders = headers.filter(header => !desktopHeaders.includes(header))

  return (
    <div className='employees__table'>
      <Table>
        <TableHead>
          <TableRow>
            {isMobile &&
              <TableHeader>
                <Text variant='subheading'></Text>
              </TableHeader>
            }
            {(isMobile ? mobileHeaders : headers).map((header) => (
              <TableHeader key={header}>
                <Text variant='subheading'>{header}</Text>
              </TableHeader>
            ))}
            <TableHeader>
              <VerticalDots />
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {employees.map((employee, index) => (
            <EmployeesElement
              headers={headers}
              key={index}
              network={network}
              employee={employee}
              updateEmployee={updateEmployee}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default EmployeesPage
