const en = {
  btn: {
    signin: 'Sign In',
    signup: 'Sign Up',
    next: 'Next',
    send: 'Send',
    save: 'Save',
    support: 'Write to Support',
    continue: 'Continue',
    cancel: 'Cancel',
    resend: 'Resend',
    reset: 'Reset',
    apply: 'Apply',
    retry: 'Retry',
    browse: 'Browse',
    change: 'Change',
    add: 'Add',
    forCoffeeShops: 'For Coffeeshops',
    forUsers: 'For Users',
    deleteProfile: 'Delete Profile',
    confirm: 'Confirm',
    newRequest: 'New Request',
    resetAll: 'Reset All',
    signInAdm: 'SignIn',
    consultation: 'Order a consultation',
  },
  input: {
    firstName: 'First Name',
    lastName: 'Last Name',
    secondName: 'Second Name',
    phone: 'Phone',
    phoneNumber: 'Phone Number',
    email: 'Email',
    password: 'Password',
    oldPassword: 'Old Password',
    passwordConfirmation: 'Password Confirmation',
    confirmPassword: 'Confirm Password',
    networkName: 'Network Name',
    website: 'Website',
    about: 'About',
    joinLoyalty: 'Join the loyalty system',
    address: 'Address',
    jobRole: 'Job Role',
    vendor: 'Coffee Shop',
    productName: 'Product Name',
    description: 'Description',
    size: 'Size',
    price: 'Price',
    supplementName: 'Supplement Name',
    category: 'Category',
    language: 'Language',
    recipientName: 'Recipient Name',
    recipientBank: 'Recipient Bank',
    iban: 'IBAN',
    checkboxIntegrated: 'CheckBox integration',
    ipnEdrpou: 'IPN/EDRPOU',
    edrpou: 'EDRPOU',
    mfo: 'Bank Code (MFO)',
    amount: 'Amount',
    smsCode: 'SMS Code',
    emailAdm: 'Email',
    passwordAdm: 'Password',
    code: 'Confirmation Code',
    companyName: 'Company name',
    checkboxLicenseKey: 'Cash desk license key',
    selectVendor: 'Select vendor',
    checkboxPinCode: 'Barista\'s pin code'
  },
  signInAdm: {
    description: 'Sign in with your data that you entered during registration.',
    form: {
      title: 'Enter Your account',
    },
  },
  actions: {
    edit: 'Edit',
    delete: 'Delete',
    deactivate: 'Deactivate',
    changePassword: 'Change password',
    invite: 'Invite',
    inviteAgain: 'Invite again',
    saving: 'Saving',
    saved: 'Saved',
  },
  weekDayShort: {
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat',
    sunday: 'Sun',
  },
  weekDayLong: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  signin: {
    title: 'Welcome back',
    description: 'Sign in with your data that you entered during registration.',
    form: {
      title: 'Enter Your account',
      noAccount: 'Don’t have an account?',
      chooseMethod: 'Sign in with',
    },
  },
  signup: {
    title: 'Welcome to Pinka',
    description: 'Please tell us a little about you and your business.',
    userForm: {
      title: 'Your Personal Info',
      haveAccount: 'Already have an account?',
      or: 'Or',
      chooseMethod: 'Sign up with',
      phone: 'Phone number',
      email: 'Email',
      resendCodeTimer: 'You can resend code in',
      resendCode: 'Resend code',
    },
    networkForm: {
      title: 'Your Business Info',
    },
  },
  forgotPassword: {
    title: 'Forgot password?',
    description: 'Please enter your email address to request a password reset.',
  },
  checkEmail: {
    title: 'Check your Email',
    description: 'The link has been sent to',
    notReceive: 'Didn’t receive the email?',
  },
  createNewPassword: {
    title: 'Create new password',
    description: 'Your new password must be different from previous used passwords.',
  },
  passwordReset: {
    title: 'Password reset',
    description1: 'Your password has been successfully reset!',
    description2: 'Click below to sign in to your account.',
    descriptionBarista: 'Now you can go to the app and sing in!',
  },
  pagination: {
    of: 'of',
    rowsPerPage: 'Rows per page',
  },
  header: {
    editProfile: 'Settings',
    logOut: 'Log Out',
    city: 'City',
    vendor: 'Coffee shop',
    filter: {
      city: 'All cities',
      vendor: 'All coffee shops',
    },
    changePassword: {
      title: 'Change Password',
      description: 'Your new password must be different from previous used passwords.',
    },
  },
  profile: {
    delete: 'Delete',
  },
  notifications: {
    title: 'Notifications',
    new: 'New',
    archive: 'Archive',
    settings: 'settings',
    delete: 'delete',
    markAllAsRead: 'mark all as read',
    noNew: 'You don’t have new notifications.',
    noOpened: 'You don’t have opened notifications.',
    orders: 'Orders',
    network: 'Network',
    succesfullyCreated: 'Succesfully created',
    succesfullyUpdated: 'Succesfully updated',
    succesfullyDeleted: 'Succesfully deleted',
    loginSuccess: 'Welcome to the admin panel',
    error: 'Something went wrong',
    notFound: 'Sorry, we couldn\'t find it.',
    sessionExpired: 'Session expired',
    subscriberCreated: 'Success! Check your email please',
    subscriberEmailDuplicate: 'Failed! Current email is already used',
    nameEmpty: 'Name cannot be empty',
    textEmpty: 'Text cannot be empty',
    nameOnlyLetters: 'Use only letters for name',
    emailEmpty: 'Email cannot be empty',
    emailInvalid: 'Email is invalid',
    mailSent: 'Sent successfully',
    maxFive: 'Please choose up to 5 images',
    updatedMap: 'Please refresh page for view new map position',
    mailBackendError: 'Can\'t send request',
    passwordResetSuccess: 'Your password successfully updated',
    passwordResetError: 'An error occured:',
    emailConfirmationSuccess: 'Your email successfully confirmed!',
    emailConfirmationRequired: 'Please confirm your email. Check your mailbox.',
    emailConfirmationError: 'An error occured:',
    passwordDoesNotMatch: 'Passwords does not match!',
    settingsChangedSuccessfully: 'Settings changed successfully!',
    somethingWentWrok: 'Something went wrong',
    codeSent: 'Sms code was sent'
  },
  sidebar: {
    myBalance: 'My Balance',
    dashboard: 'Dashboard',
    transactions: 'Transactions',
    orders: 'Orders',
    network: 'Network',
    employees: 'Employees',
    menu: 'Menu',
    withdrawFunds: 'Withdraw Funds',
    settings: 'Settings',
    help: 'Help',
  },
  help: {
    contact: 'Contact Us',
  },
  dashboard: {
    title: 'Dashboard',
    dateRange: 'Date range',
    resultsFor: 'Results for',
    cityFilter: 'all cities',
    vendorFilter: 'all coffee shops',
    cashTurnover: 'Cash Turnover',
    total: 'Total',
    totalOrders: 'Total Orders',
    finished: 'Finished',
    canceled: 'Canceled',
    in_progress: 'In progress',
    new: 'New',
    ready: 'Ready',
    failed: 'Failed',
    salesByCategory: 'Sales by Category',
    drinks: 'Drinks',
    desserts: 'Desserts',
    food: 'Food',
    goods: 'Goods',
    supplements: 'Supplements',
    baristaEfficiency: 'Barista Efficiency',
    sortBy: 'Sort by',
    barista: 'Barista',
    revenueUAH: 'Revenue, uah',
    revenue: 'Revenue',
    ascending: 'Ascending',
    descending: 'Descending',
    vendorComparison: 'Coffee Shop Comparison',
    vendor: 'Coffee Shop',
    calendarYear: 'Calendar year',
    calendarMonth: 'Calendar month',
    customRange: 'Custom range',
    empty: {
      title: 'There isn’t any data to display',
      description: {
        vendor: 'A chart will appear after you add a coffee shop to your network.',
        baristas: 'A chart will appear after you add a baristas to your network.',
      },
    },
  },
  validators: {
    notEmpty: 'This field must not be empty',
    whiteSpace: 'The field must not consist of only spaces',
    invalidField: 'This field is invalid',
    numberFormat: 'Format: 0000.00 or 0000',
    expiredData: 'This data is expired',
    isMatching: 'The entered passwords do not match',
    isFilledShort: 'Cannot be blank',
    edrpouRegex: 'Only 8 - 10 digits',
    mfoRegex: 'Only 6 digits',
    minimum: 'Minimum',
    characters: 'characters',
    notValidUrl: 'This is not valid URL',
    correctAddress: 'The address must contain the city, street and number',
    phone: {
      withoutPlus: 'Format: 380999999999',
    },
    timeFormat: 'Please enter the time in the correct format 00:00 and do not use any other characters.',
    timeRange: 'Please enter correct time range',
    liqpayActivationCodeRegex: 'Only 8 digits'
  },
  filters: {
    today: 'Today',
    week: 'This week',
    month: 'This month',
    last7: 'Last 7 days',
    last30: 'Last 30 days',
    custom: 'Custom range',
    notSelected: 'not selected',
  },
  orders: {
    title: 'Orders',
    resetAll: 'Reset All',
    filters: 'Filters',
    empty: 'The list of all orders will be displayed here.',
    orderId: 'Order ID',
    date: 'Date',
    vendor: 'Coffee Shop',
    barista: 'Barista',
    payment: 'Payment',
    status: 'Status',
    finished: 'finished',
    canceled: 'canceled',
    in_progress: 'in progress',
    new: 'new',
    ready: 'ready',
    failed: 'failed',
    uah: 'UAH',
    order: 'Order',
    city: 'city',
    servicesBy: 'Services by',
    orderDetails: 'Order Details',
    total: 'Total',
    discount: 'Discount',
    toBePaid: 'To Be Paid',
    allBaristas: 'All baristas',
    timePeriod: 'Time period',
    orderStatus: 'Order status',
    anyStatus: 'Any status',
  },
  network: {
    title: 'Network',
    addVendor: 'Add Coffee Shop',
    customerFeedback: 'Customer feedback',
    editVendor: 'Edit coffee shop',
    numberOfVendors: 'Number of coffee shops',
    noVendors: 'You haven’t added any coffee shops to your network yet.',
    details: 'Network details',
    edit: 'Edit network',
    vendors: 'Coffee shops',
    deleteVendor: 'Delete coffee shop',
    viewMenu: 'View Menu',
    about: 'About',
    emptyDescription: 'Tell customers a few words about your network...',
    rating: 'Rating',
    stars_one: 'star',
    stars_few: 'stars',
    stars_many: 'stars',
    stars_other: 'stars',
    noReviews: 'There are no reviews for this coffee shop yet.',
    basedOn: 'Based on',
    reviews: 'reviews',
    deleteMessage: 'All related data will also be removed from the database.',
    open: 'open',
    closed: 'closed',
    openingHours: 'Opening hours',
    loadingResults: 'Loading results',
    map: 'Map',
    photos: 'Photos',
    loadImages: 'Upload',
    activationCode: 'Please type your activation code',
  },
  vendor: {
    mainInfo: 'Main Information',
    checkboxIntegration: 'Checkbox'
  },
  uploader: {
    maxFiles: 'You can only upload a maximum of',
    files: 'files',
    uploadingFailed: 'Uploading failed',
    fileIsLargerThan5MB: 'The file is larger than 5 Mb',
    upTo5MB: 'up to 5 Mb',
    dragAndDropToUploaderOr: 'Drag and drop to upload or',
    uploadUpTo7Photos: 'Upload up to 7 photos',
    photo: 'Photo',
  },
  employees: {
    title: 'Employees',
    rating: 'Rating',
    empty: 'Your added employees will be displayed here.',
    addEmployee: 'Add Employee',
    addNewEmployee: 'Add new employee',
    employee: 'Employee',
    barista: 'Barista',
    manager: 'Manager',
    owner: 'Owner',
    status: 'Status',
    role: 'Role',
    generalInfo: 'General info',
    vendor: 'Coffee Shop',
    email: 'Email',
    phone: 'Phone',
    active: 'act.',
    inactive: 'inact.',
    contacts: 'Contacts',
    position: 'Position',
    changePassword: 'Change password',
    deactivateTitle: 'Deactivate employee?',
    deactivateText: 'All related data will remain in database.',
    noVendor: 'No Coffee Shops',
    noVendorText: 'Please create a coffee shop before adding employees.',
    feedbacks_one: '{{count}} feedbacks',
    feedbacks_other: '{{count}} feedbacks',
    loginData: 'Login data'
  },
  menu: {
    title: 'Menu',
    poster: 'You use the Poster service for the menu',
    back: 'Back',
    inputTitle: 'Enter Company Name',
    deletePoster: 'Delete Poster',
    companyName: 'Company name',
    submit: 'Submit',
    empty: 'There are currently no types available.',
    emptyCategories: 'The menu categories you added will be displayed here.',
    noMoreCategories: 'You have added all categories.',
    addCategory: 'Add Category',
    removeCategoryText: 'All added products will also be removed from the menu.',
    areYouSure: 'Are you sure?',
    addProduct: 'Add Product',
    deleteCategory: 'Delete Category',
    addSupplement: 'Add Supplement',
    coffeeType: 'Coffee type',
    coffee: 'Coffee',
    cocoa: 'Cocoa',
    tea: 'Tea',
    smoothies: 'Smoothies',
    'refreshing-drinks': 'Refreshing Drinks',
    sweets: 'Sweets',
    'sweet-bakery': 'Sweet bakery',
    'ice-cream': 'Ice cream',
    salad: 'Salad',
    bakery: 'Bakery',
    sandwiches: 'Sandwiches',
    'fast-food': 'Fast food',
    breakfasts: 'Breakfasts',
    'packaged-goods': 'Packaged goods',
    'goods-for-coffee': 'Goods for coffee',
    milk: 'Milk',
    toppings: 'Toppings',
    additionally: 'Additionally',
    arabica: 'Arabica',
    robusta: 'Robusta',
    mix: 'Mix',
    uah: 'uah',
    availability: 'Availability',
  },
  month: {
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
  },
  withdrawFunds: {
    title: 'Withdraw Funds',
    transaction: 'Transaction',
    addWithdrawMethod: 'Add Withdraw Method',
    create: {
      title: 'Withdrawal',
      paymentMethod: 'Payment Method',
      amount: 'Amount',
      uah: 'uah',
      notFound: 'Not Found',
      availableFunds: 'Available Funds',
      addPaymentMethod: 'Add payment Method',
      notPaymentMethod: 'You haven\'t added a payment method yet. Please go to Settings.',
    },
    show: {
      title: 'Withdrawal',
      recipientBank: 'Recipient\'s Bank',
      recipientName: 'Recipient\'s Name',
      bankCode: 'EDRPOU',
      recipientAccount: 'Recipient\'s Account',
      mfoCode: 'MFO Code',
      amount: 'Amount',
      transactionId: 'Transaction ID',
    },
    element: {
      bankAccount: 'Bank account',
    },
    filters: {
      timePeriod: 'Time Period',
      paymentStatus: 'Payment Status',
    },
    hints: {
      availableNew: 'The new transaction will be available after the completion of the current one.',
    },
    tableHeaders: {
      id: 'Withdrawal ID',
      date: 'Date',
      amount: 'Amount',
      method: 'Withdrawal Method',
      status: 'Status',
    },
    statusAny: 'Any status',
    statusSent: 'Request Sent',
    statusPending: 'In Progress',
    statusSuccess: 'Completed',
    statusFailed: 'Error',
    withdrawalHistory: 'Withdrawal History',
    empty: {
      noWithdrawal: 'No Withdrawal To Display',
      noResult: 'No results match the filter criteria.',
      tryAgain: 'Adjust or reset filters and try again',
    },
  },
  settings: {
    title: 'Settings',
    editProfile: {
      title: 'Edit Profile',
      ukrainian: 'Ukrainian',
      english: 'English',
      profileInfo: 'Profile Info',
      deleteProfile: 'Delete Your Profile?',
    },
    deleteProfile: {
      title: 'Delete Profile?',
    },
    withdrawalMethod: {
      title: 'Withdrawal Method',
      myMethods: 'My Withdrawal Methods',
      newMethod: 'New Withdrawal Method',
      bankAccount: 'Bank Account',
      setAsDefault: 'Set as default withdrawal method',
      recipient: 'Recipient',
      delete: 'Delete Withdrawal Method?',
      wrongCode: 'Wrong code',
    },
    notifications: {
      title: 'Notifications',
      description: 'You can choose which notifications you want to receive.',
      orderFinished: 'Order Finished',
      orderCanceled: 'Order Canceled',
      customerFeedback: 'Customer Feedback',
      withdrawalSuccess: 'Withdrawal was successful',
      withdrawalFailed: 'Withdrawal has failed',
      orderFinishedText: 'Notifications about fihished orders.',
      orderCanceledText: 'Notifications about canceled orders.',
      customerFeedbackText: 'Notifications about new feedback about your coffee shops.',
      withdrawalSuccessText: 'Notifications about successful withdrawals.',
      withdrawalFailedText: 'Notifications about failed withdrawals.',
    },
  },
  deleteInfo: {
    description: 'All related data will also be removed from the database.',
    balanceInfo: 'Are you sure you want to delete your profile? You still have money on your account ',
  },
  liqpayAccount: {
    title: 'Create Liqpay Account',
  },
  receipts: {
    title: 'Receipts',
    order: 'Order',
    uah: 'UAH',
    receipts: 'Receipts (UAH)',
    date: 'Date',
    loyalty: 'loyalty',
    commission: 'commission',
    total: 'Total'
  },
  hint: {
    checkboxIntegration: 'The Checkbox system is used for the fiscalization of orders. To do this, you will need to ' +
      'enter the Cash Register License Key when creating a coffee shop and a PIN code when adding a barista. ' +
      'This requires separate registration in the Checkbox system, but as a result, orders will be fiscalized ' +
      'automatically. Without integration with Checkbox, the barista will fiscalize orders through the café\'s ' +
      'PPR (Professional Self-Service Terminal).',
    loginData: 'Login details for the Pinka Barista application.'
  },
  tutorial: {
    dashboard: {
      title: 'Welcome to the Pinka system!',
      textFirstOne: 'There are a few steps left to get started.',
      textFirstTwo: '1. On the \'Network\' page, add information about your coffee shop.',
      textSecond: '2. On the \'Menu\' page, add or import drinks.',
      textThird: '3. On the \'Employees\' page, add barista credentials.'
    },
  },
  networkInfo: {
    description: 'Description',
    creationDate: 'Creation date',
    Hidden: 'Hidden',
  },
  ownerInfo: {
    owner: 'Owner',
    email: 'Email',
    phoneNumber: 'Phone Number',
    liqpayPhoneNumber: 'Liqpay Phone Number'
  },
  common: {
    empty: 'Empty',
  },
}

export { en }
