import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { staffNetworksGetRequest } from '../../../../store/staff/networks/actions'
import NetworkHeader from './NetworkHeader'
import NetworkInfo from './NetworkInfo'
import OwnerInfo from './OwnerInfo'
import VendorsInfo from './VendorsInfo/VendorsInfo'

const NetworkView = () => {
  const dispatch = useDispatch()
  const { id } = useParams()
  const { network, fetching } = useSelector(state => state.staffNetworks)

  useEffect(() => {
    dispatch(staffNetworksGetRequest(id))
  }, [])

  if (fetching || !network) return null

  return (
    <div className='staff-network'>
      <NetworkHeader network={network} />
      <NetworkInfo network={network} />
      <OwnerInfo network={network} />
      <VendorsInfo vendors={network.vendors} />
    </div>
  )
}

export default NetworkView
