import React from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '../../../UIkit'
import InfoRow from '../../components/InfoRow'

const OwnerInfo = ({ network }) => {
  const { t } = useTranslation()

  const { owner, liqpayAccount } = network 

  if (!owner) return null

  return (
    <div className='staff-info-block'>
      <Text variant='h2'>{t('ownerInfo:owner')}</Text>

      <div className='staff-info-block__block'>
        <div style={{ backgroundImage: `url(${owner.profile?.avatarUrl})` }} className='edit-employee__avatar' />
        <Text variant='h3'>{owner.profile?.fullName}</Text>
      </div>

      <InfoRow title={t('ownerInfo:email')} text={owner.email || ''} />
      <InfoRow title={t('ownerInfo:phoneNumber')} text={owner.phoneNumber || ''} />
      <InfoRow title={t('ownerInfo:liqpayPhoneNumber')} text={liqpayAccount?.phone || ''} />
    </div>
  )
}

export default OwnerInfo
