import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Switch } from '../../../UIkit'
import InfoRow from '../../components/InfoRow'
import { staffNetworksUpdateRequest } from '../../../../store/staff/networks/actions'

const NetworkInfo = ({ network }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleUpdateStatus = () => {
    const status = network.status === 'active' ? 'hidden' : 'active'
    dispatch(staffNetworksUpdateRequest({ id: network.id, params: { status } }))
  }

  return (
    <div className='staff-info-block'>
      <InfoRow title={t('networkInfo:description')} text={network.description} />
      <InfoRow title='Url' text={network.url} />
      <InfoRow title={t('networkInfo:creationDate')} text={moment(network.createdAt).format('DD-MM-YYYY')} />
      <InfoRow title={t('networkInfo:hidden')}>
        <Switch index={0} checked={network.status === 'hidden'} onChange={() => handleUpdateStatus()} />
      </InfoRow>
    </div>
  )
}

export default NetworkInfo
